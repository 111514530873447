<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row class="justify-content-between">
      <b-col cols="auto">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="auto"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="changeInstance"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-group>
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-input-group-text class="bg-white border-right-0">
                <b-icon icon="search"></b-icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              class="border-left-0"
              id="filter-input"
              type="search"
              v-model="filter"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <pre>{{tableData}}</pre> -->
    <!-- Main table element -->
    <b-table
      :fields="fields"
      :items="tableData"
      :per-page="perPage"
      :current-page="currentPage"
      head-variant="dark"
      primary-key="id"
      bordered
      show-empty
      small
      :filter="filter"
      :busy="tblBusy"
      ref="table"
    >
      <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <strong>Loading...</strong>
          </div>
      </template>
      <template #cell(actions)="row">
        <div class="d-flex justify-content-end justify-content-around">
          <b-button
            size="sm"
            variant="success"
            :to="{ name: 'farmer-details', params: row.item }"
          >
            <b-icon icon="eye-fill"></b-icon>
          </b-button>
        </div>
      </template>

      <template #cell(fff)="data">
          <span v-if="data.item.fff">
          {{ data.item.fff }}
          </span>
          <span v-else>Calculating...</span>
        </template>
    </b-table>


    <b-row class="justify-content-center">
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getProfileList } from "@/services/profile";
import { getForm,getCertificate } from "@/services/forms";
import Vue from "vue";


Vue.prototype.perPageSum = null;

export default {
  data() {
    return {
      forms: [],
      tableData: [],
      currentPage: 1,
      filter:"",
      tblBusy:false,
      perPage: 10,
      pageOptions: [5,10, 15, { value: 100, text: "Show a lot" }],
      fields: [
        { key: "title", label: "Outgrower name in system" },
        { key: "fff", label: "FFF %" },
        { key: "actions", label: "" }
      ]
    };
  },
  computed: {
    totalRows() {
      return this.tableData.length ?? 0;
    }
  },
  mounted() {
    if (this.$auth.check()) {
      this.getProfileList();
      this.getForm();
      if (Vue.prototype.perPageSum){
        this.perPage = Vue.prototype.perPageSum
      }
    }
  },
  methods: {
    changeInstance(){
        Vue.prototype.perPageSum = this.perPage
    },
    async getAvgPerc(uniqueId) {
      const avg = await getCertificate(uniqueId).then(form_cert => {
        let totalScores = 0;
        const cert = form_cert?.cert_json ? JSON.parse(form_cert.cert_json).farm_cert : null;
        cert?.forEach(form => {
          totalScores = totalScores + form.performance_rate;
        });
        const avgPerc =
          totalScores > 0 ? Math.round((totalScores / 500) * 100) : null;
        return avgPerc;
      });
      return avg;
    },
    async getProfileList() {
      this.tblBusy = true;
      await getProfileList().then(profiles => {
        this.tableData = profiles ?? [];
        if (this.tableData.length < 1){
          this.tblBusy = false;
        }
        if (profiles) {
          profiles.forEach(async (profile) => {
            await this.getAvgPerc(profile.uniqueId).then(avgPerc => {
              profile.fff = avgPerc ?? "N/A";
            }).finally(() => {
              this.$refs.table.refresh()
              this.tblBusy = false
            })
          });
        }
      });
    },
    async getForm() {
      await getForm().then(result => {
        result.sort((a, b) =>
          this.$moment(a.created_date, "DD-MM-YYYY h:mm").format() <
          this.$moment(b.created_date, "DD-MM-YYYY h:mm").format()
            ? 1
            : -1
        );
        const locked_forms = result.filter(form => {
          return form.form_locked === "true";
        });

        const uniqueObj = Array.from(
          new Set(locked_forms.map(a => a.form_name))
        ).map(form_name => {
          return locked_forms.find(a => a.form_name === form_name);
        });

        this.forms = uniqueObj;
      });
    },
  }
};
</script>
